import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import benefitsImgSrc from 'img/benefits-img.webp';

import './benefits.scss';

export default function Benefits () {
    const { t: translate } = useTranslation('home');

    return(
        <Box className={'why__box'}>
            <Box className={"why__text"}>
                <h2>
                    {translate('benefits')}
                </h2>

                <ul>
                    <li>
                        <strong>
                            {translate('capitalBenefitStrong')}
                        </strong>

                        {translate('capitalBenefit')}
                    </li>

                    <li>
                        <strong>
                            {translate('networksBenefitStrong')}
                        </strong>

                        {translate('networksBenefit')}
                    </li>

                    <li>
                        <strong>
                            {translate('marketplaceBenefitStrong')}
                        </strong>

                        {translate('marketplaceBenefit')}
                    </li>

                    <li>
                        <strong>
                            {translate('trainingBenefitStrong')}
                        </strong>

                        {translate('trainingBenefit')}
                    </li>
                </ul>
            </Box>

            <img alt="" src={benefitsImgSrc} />

            <Box className={'why__img'}>&nbsp;</Box>
        </Box>
    )
};