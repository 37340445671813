import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';

import { ReactComponent as Funding } from 'img/investor/funding.svg';
import { ReactComponent as Storytelling } from 'img/investor/storytelling.svg';
import { ReactComponent as Mindset } from 'img/investor/mindseat.svg';
import { ReactComponent as Operations } from 'img/investor/operations.svg';

import './investor.scss';

export default function Investor() {
    const { t: translate } = useTranslation('home');

    const isMobileVersion = useMediaQuery((theme) => theme.breakpoints.down(900));

    return (
        <Box className={'investor__box'}>
            <h2>
                {translate('fromStartupToUnicorn')}
            </h2>

            <p className="investor__pinkText">
                {translate('accelerationPath')}
            </p>

            <div className="investor__description">
                <span>
                    {translate('descriptionAccelerationPath')} {!isMobileVersion && <br />}
                </span>

                <span>
                    <strong>
                        {translate('descriptionStrongAccelerationPath')}
                    </strong>
                </span>
            </div>

            <Grid
                className={'investor__grid'}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Box className={'investor__item'}>
                    <Funding />

                    <h4>
                        {translate('fundingStrategyStrong')}
                    </h4>

                    <p>
                        {translate('fundingStrategy')}
                    </p>
                </Box>

                <Box className={'investor__item'}>
                    <Storytelling />

                    <h4>
                        {translate('storytellingStrategyStrong')}
                    </h4>

                    <p>
                        {translate('storytellingStrategy')}
                    </p>
                </Box>

                <Box className={'investor__item'}>
                    <Mindset />

                    <h4>
                        {translate('mindsetStrategyStrong')}
                    </h4>

                    <p>
                        {translate('mindsetStrategy')}
                    </p>
                </Box>

                <Box className={'investor__item'}>
                    <Operations />

                    <div className="description">
                        <h4>
                            {translate('operationsStrategyStrong')}
                        </h4>

                        <p>
                            {translate('operationsStrategy')}
                        </p>
                    </div>
                </Box>
            </Grid>
        </Box>
    )
}