import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import './faq.scss';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
)) (({ theme }) => ({
    border: 0,

    '.MuiCollapse-wrapperInner': {
      background:'#f5f5f7',

        [theme.breakpoints.down(900)]: {
            background: '#e9ecef7f !important',
        },
    },

    '&:not(:last-child)': {
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottom: '1.1px solid',
        borderImageSource: `linear-gradient(to top,
            #d99692, #d99692, #fbc1b7, #fdd4cf, #fdcbc3, #f9b4ac, #d38883, #c76b65, #da9995, #f9b7af, #fdc8c1)`,
        borderImageSlice: 1,
    },

    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '18px' }} />}
        {...props}
    />
)) (({ theme }) => ({
    maxHeight: '78px',
    border: 0,
    padding: 0,
    backgroundColor:'#f5f5f7',

	[theme.breakpoints.down(900)]: {
      maxHeight: 'unset',
      justifyContent: 'flex-start',
      background: '#e9ecef7f !important',
    },    

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'none',
    },
    '& .MuiAccordionSummary-expandIconWrapper svg': {
        display: 'none',
    },

    '& .MuiAccordionSummary-content': {
      marginLeft: 0,

      [theme.breakpoints.down(900)]: {
        maxWidth: '92%',
      },
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    padding: 0,
    borderTop: 0,
}));

export default function Faq () {
    const { t: translate } = useTranslation('home');

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <Box className={'faq__box'} id={'faq'}>
            <Box className={'faq__left'}>
                <h2>
                    {translate('faqs')}
                </h2>
            </Box>

            <Box className={'faq__accordion'}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <p><strong>{translate('faq1')}</strong></p>
                    </AccordionSummary>

                    <AccordionDetails>
                        <p>
                            {translate('faqAnswer1')}
                        </p>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <p><strong>
                            {translate('faq2')}
                        </strong></p>
                    </AccordionSummary>

                    <AccordionDetails>
                        <p>
                            {translate('faqAnswer2Part1')}
                            <br /><br />
                            {translate('faqAnswer2Part2')}
                        </p>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <p><strong>
                            {translate('faq3')}
                        </strong></p>
                    </AccordionSummary>

                    <AccordionDetails>
                        <p>
                            {translate('faqAnswer3')}
                        </p>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                        <p><strong>
                            {translate('faq4')}
                        </strong></p>
                    </AccordionSummary>

                    <AccordionDetails>
                        <p>
                            {translate('faqAnswer4')}
                        </p>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    )
}
