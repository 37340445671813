import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import simpleFetch from 'very-simple-fetch';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import '@csstools/normalize.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Input,
  TextField,
} from '@mui/material';
import { createTheme, ThemeProvider  } from '@mui/material/styles';

import { THEME_CONFIG } from 'constants/_pages/contact';

import Modals from 'components/modal';

import email from 'img/email.png';

import '../App.scss';

const theme = createTheme(THEME_CONFIG);

const Contact = () => {
    const { t: translate } = useTranslation('contact');

    const key = global.config.recaptcha_site_key;

    const {
      register,
      handleSubmit,
      reset,
      control,
      formState: { errors },
    } = useForm();

    const [open, setOpen] = React.useState(false);

    const onSubmit = (data) => {
        const response = simpleFetch({
           url: global.config.webhook_contact,
            method: 'POST',
            mode: 'no-cors',
            headers: new Headers({'Content-Type':'application/json'}),
            body: JSON.stringify(data),
        }).then(() => {
            setOpen(true);
            document.getElementById('form__contact').reset();
            reset();
        });

        if (response) {
            console.log(response)
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className={'contact__box'}>
                <Box className={'contact__banner'}>
                    <h2>{translate('howCanHelp')}</h2>
                </Box>

                <div
                  className="contact__content"
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-top"
                >
                    <Box className={'contact__help-box'} sx={{alignItems: 'flex-end'}}>
                        <h3>
                          {translate('touchWithUs')}
                        </h3>

                        <p>
                          {translate('description')}
                        </p>

                        <List className={'contact__list'} sx={{ width: '100%', background: 'none' }}>
                            <ListItem
                                className="contact__help-email"
                                alignItems="flex-start"
                                sx={{padding: '15px', marginBottom: '30px'}}
                            >
                                <ListItemAvatar sx={{marginTop: 0}} className={'contact__help-avatar'}>
                                    <Avatar alt="" src={email} />
                                </ListItemAvatar>

                                <ListItemText
                                    sx={{margin: 0}}
                                    className={'contact__help-text'}
                                    primary={'E-mail'}
                                    secondary={
                                        <React.Fragment>
                                            <a
                                                className={'contact__help-typografy'}
                                                href="mailto:info@escueladeinversoras.com"
                                            >
                                                {translate('emailAddres')}
                                            </a>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <GoogleReCaptchaProvider reCaptchaKey={key}>
                        <Box
                            className="form__contact"
                            noValidate
                            id="form__contact"
                            component="form"
                            autoComlete="off"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Box className="nameBox" sx={{position: 'relative'}}>
                                <Input className={'input-text'}
                                    {...register('fullName', {required: true, pattern: /^[A-Za-z1-9\s_-]+$/i} )}
                                    placeholder={translate('namesPlaceholder')}
                                    onBlur={e => e.target.value = e.target.value.trim()}
                                />

                                <p className={'error'}>{errors.fullName?.type === 'required' && translate('requiredField')}</p>
                                <p className={'error'}>{errors.fullName?.type === 'pattern' && translate('invalidCharacters')}</p>
                            </Box>

                            <Box className="phoneInputBox" sx={{position: 'relative'}}>
                                <PhoneInputWithCountry
                                    className={'phone__box'}
                                    international
                                    defaultCountry="US"
                                    name="phone"
                                    id="phone__input"
                                    placeholder={translate('phonePlaceholder')}
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: isPossiblePhoneNumber,
                                    }}
                                />

                                <p className={'error'}>{errors.phone?.type === 'required' && translate('requiredField')}</p>
                                <p className={'error'}>{errors.phone?.type === 'validate' && 'Please enter phone number'}</p>
                            </Box>

                            <Box className="emailBox" sx={{position: 'relative'}}>
                                <Input className={'input-text'}
                                    {...register('email',
                                        {
                                            required: true,
                                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        }
                                    )}
                                    placeholder={'E-mail'}
                                />

                                <p className={'error'}>{errors.email?.type === 'required' && translate('emailIsRequired')}</p>
                                <p className={'error'}>{errors.email?.type === 'pattern' && translate('emailIsNotValid')}</p>
                            </Box>

                            <Box className="textareaBox" sx={{position: 'relative'}}>
                                <TextField
                                    className="textarea"
                                    multiline
                                    id="outlined-multiline-static"
                                    rows={5}
                                    placeholder={translate('yourMessagePlaceholder')}
                                    {...register('message', {required: true})}
                                />

                                <p className={'error'}>
                                  {errors.message?.type === 'required' && translate('requiredField')}
                                </p>
                            </Box>

                            <Box className={'bottom'}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit(onSubmit)}
                                    sx={{ width: '137px'}}
                                    className={'btn'}
                                >{translate('send')}</Button>
                            </Box>
                        </Box>
                    </GoogleReCaptchaProvider>

                    <Modals
                        className="contact__modal"
                        text="We’re thrilled to hear from you. Our team will get back in touch with you soon!"
                        open={open}
                    />
                </div>
            </Box>
        </ThemeProvider>
    );
};

export default Contact;