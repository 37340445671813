export const CONTACT_PAGE = '/contact';

export const LANGUAGES = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'es',
    name: 'Española',
  },
];
