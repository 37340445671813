import '@csstools/normalize.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Container from '@mui/material/Container';
import { createTheme, ThemeProvider  } from '@mui/material/styles';

import { THEME_CONFIG } from 'constants/_pages/home';

import Banner from "components/banner/banner";
import Investor from "components/investor/investor";
import Benefits from "components/benefits/benefits";
import RoadToSuccess from "components/RoadToSuccess/index";
import About from "components/about/about";
import Steps from "components/steps/steps";
import Command from "components/comand/command";
import Faq from "components/faq/faq";

import '../App.scss';

const scrollToDiv = id =>{
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });
}

const hash = window.location.search;

const scroll = () => {
    if (hash === '?about') {
        scrollToDiv('about');
    } else if (hash === '?command') {
        scrollToDiv('command');
    } else if (hash === '?faq') {
        scrollToDiv('faq');
    }
};

const theme = createTheme(THEME_CONFIG);

const Home = () => {
  window.addEventListener('load', scroll);

  return (
    <ThemeProvider theme={theme}>
      <Banner />

      <Container maxWidth="lg" className={'container'}>
        <Investor />
      </Container>

      <div className="benefits-bg">
        <Container maxWidth="xl" className={'container-big'}>
          <Benefits />
        </Container>
      </div>

      <RoadToSuccess />

      <About />

      <Steps />

      <Command />

      <div className={'container-faq'}>
        <Faq />
      </div>
    </ThemeProvider>
  );
}

export default Home;
