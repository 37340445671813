import React, {
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box, Grid, useMediaQuery } from '@mui/material';

import unicornSrc from 'img/road-to-success/unicorn.png';
import arrowSrc from 'img/road-to-success/arrow.webp';
import scoutingSrc from 'img/road-to-success/scouting.svg';
import assesmentSrc from 'img/road-to-success/assesment.svg';
import marketplaceSrc from 'img/road-to-success/marketplace.svg';
import trainingSrc from 'img/road-to-success/training.svg';
import retreatSrc from 'img/road-to-success/retreat.svg';
import graph from 'img/road-to-success/Graphic_mobile.svg';

import './RoadToSuccess.scss';

const SUCCESS_PATH_BLOCKS = [
  {
    className: 'scouting',
    imgSrc: scoutingSrc,
    alt: "scouting",
    text: 'roadToSuccessScouting',
  },
  {
    className: 'scoutingArrow',
    imgSrc: arrowSrc,
    alt: 'arrow',
  },
  {
    className: 'firstBorder',
  },
  {
    className: 'marketplace',
    imgSrc: marketplaceSrc,
    alt: "marketplace",
    text: 'roadToSuccessMarketplace',
  },
  {
    className: 'secondBorder',
  },
  {
    className: 'assesment',
    imgSrc: assesmentSrc,
    text: 'roadToSuccessAssesment',
  },
  {
    className: 'assesmentArrow',
    imgSrc: arrowSrc,
    alt: 'arrow',
  },
  {
    className: 'training',
    imgSrc: trainingSrc,
    text: 'roadToSuccessTraining',
  },
  {
    className: 'trainingArrow',
    imgSrc: arrowSrc,
    alt: 'arrow',
  },
  {
    className: 'retreat',
    imgSrc: retreatSrc,
    text: 'roadToSuccessRetreat',
  },
  {
    className: 'thirdBorder',
  },
];

const RoadToSuccess = () => {
  const { t: translate } = useTranslation('home');

  const matchedMedia = useMediaQuery((theme) => theme.breakpoints.down(900));

  return (
    <Box className="roadToSuccess__box">
      <h2>
        {translate('roadToSuccess')}
      </h2>

      <p className="roadToSuccess__pinkText">
        {translate('roadToSuccessDescription')}
      </p>

      <Grid
        className="roadToSuccess__grid"
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <img
          className="roadToSuccess__unicornImg"
          alt="unicorn"
          src={unicornSrc}
        />

        {matchedMedia &&
          <img
            className="pathImgMobile"
            alt="graph"
            src={graph}
          />
        }

        {!matchedMedia && 
          <div className="roadToSuccess__path">
            {SUCCESS_PATH_BLOCKS.map((element, index) => (
                <div
                  className={clsx('roadToSuccess__pathElement', element.className)}
                  key={`roadToSuccess-${index}`}
                >
                  {element.imgSrc && (
                    <div className="imageElement">
                      <img
                          alt={element.alt}
                          src={element.imgSrc}
                      />

                      {element.text && (
                          <span>
                          {translate(element.text)}
                          </span>
                      )}
                    </div>
                  )}
                </div>
            ))}
          </div>
        }
      </Grid>
    </Box>
  )
}

export default memo(RoadToSuccess);
