import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Button from "@mui/material/Button";

import { applyButtonClick } from 'utils/common';

import { ReactComponent as Plane } from 'img/icon/plane.svg';
import { ReactComponent as Conversation } from 'img/icon/conversation.svg';
import { ReactComponent as Comment } from 'img/icon/comment.svg';
import { ReactComponent as Tick } from 'img/icon/tick.svg';

import './steps.scss';

export default function Steps() {
    const { t: translate } = useTranslation('home');

    return (
        <Box className={'steps__box'}>
            <Box className={'container-big'}>
                <Box className={'steps__text'}>
                    <h2>
                        {translate('joining')} <br /><strong>{translate('joiningIsSimple')}</strong>
                    </h2>

                    <Button
                        className="btn"
                        variant="contained"
                        onClick={applyButtonClick}
                    >
                        {translate('applyNowBtn')}
                    </Button>
                </Box>

                <Box className={'steps__box-list'}>
                    <ul>
                        <li>
                            <span className={'number'}>
                                <span>
                                    01
                                </span>
                            </span>

                            <span className={clsx('text', 'firstItem')}>
                                {translate('step1')}
                                <Plane />
                            </span>
                        </li>

                        <li>
                            <span className={'number'}>
                                <span>
                                    02
                                </span>
                            </span>

                            <span className={clsx('text', 'secondItem')}>
                                {translate('step2')}
                                <Conversation />
                            </span>
                        </li>

                        <li>
                            <span className={'number'}>
                                <span>
                                    03
                                </span>
                            </span>

                            <span className={clsx('text', 'thirdItem')}>
                                {translate('step3')}
                                <Comment />
                            </span>
                        </li>

                        <li>
                            <span className={'number'}>
                                <span>
                                    04
                                </span>
                            </span>
                            
                            <span className={clsx('text', 'lastItem')}>
                                {translate('step4')}
                                <Tick />
                            </span>
                        </li>
                    </ul>
                </Box>
            </Box>
        </Box>
    )
}