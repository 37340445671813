import React, {
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { applyButtonClick } from 'utils/common';

import bannerSrc from 'img/bg-main-banner.webp';

import './banner.scss'

export default function Banner () {
    const { t: translate } = useTranslation('home');

    const [visible, setVisible] = useState(true);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        const form = document.getElementById('form');

        if (form) {
            let offsetTopEnd = form.offsetTop - form.offsetHeight + 400;

            if (scrolled < offsetTopEnd) {
                setVisible(true)
            } else {
                setVisible(false)
            }
        }
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <Box className={'banner__box'}>
            <img alt="banner" src={bannerSrc} />

            <Box className={'banner__text'}>
                <h2>
                    {translate('bannerHeader')}
                </h2>

                <p className="firstText">
                    {translate('bannerStrongDescription')}
                </p>

                <p className="secondText">
                    {translate('bannerDescription')}
                </p>

                <Button
                    className="btn nomobile"
                    variant="contained"
                    onClick={applyButtonClick}
                >
                    {translate('applyNowBtn')}
                </Button>

                <Button
                    className="btn mobile"
                    variant="contained"
                    style={{display: visible ? 'inline' : 'none'}}
                    onClick={applyButtonClick}
                >
                    {translate('applyNowBtn')}
                </Button>
            </Box>
        </Box>
    );
};