import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import './about.scss';

const ABOUT_BLOCKS = [
  {
    header: 'aboutExecutiveClinic',
    description: 'aboutExecutiveClinicDescription',
  },
  {
    header: 'aboutOperationsClinic',
    description: 'aboutOperationsClinicDescription',
  },
  {
    header: 'aboutRetreat',
    description: 'aboutRetreatDescription',
  },
];

export default function About() {
  const { t: translate } = useTranslation('home');

  return (
    <Box className={'about__box'} id={'about'}>
      <Box className={'container'}>
        <h2>
          <strong>Unicornias Lab </strong>
            {translate('about')}
        </h2>

        <Grid
          className="about__grid"
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {ABOUT_BLOCKS.map((block, index) => (
            <Box className="about__item" key={`about-${index}`}>
              <h4>
                {translate(block.header)}
              </h4>

              <p>
                {translate(block.description)}
              </p>
            </Box>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}