import React, { memo } from 'react';
import { useLocation } from 'react-router';

import { Container } from '@mui/material';

import { CONTACT_PAGE } from 'constants/common';
import { THEME_CONFIG as THEME_CONFIG_CONTACT } from 'constants/_pages/contact';
import { THEME_CONFIG as THEME_CONFIG_HOME } from 'constants/_pages/home';

import Footer from 'components/footer/footer';
import Header from 'components/header/header';

import './MainLayout.scss';

const MainLayout = ({
  children,
}) => {
  const { pathname } = useLocation();

  const isContact = pathname === CONTACT_PAGE;

  return (
    <div className="layoutContainer">
      <Container
        className="container"
        maxWidth={isContact ? THEME_CONFIG_CONTACT.breakpoints.values.xl : THEME_CONFIG_HOME.breakpoints.values.lg}
      >
        <Header />
      </Container>

      <main className="mainContent">
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default memo(MainLayout);
