import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMediaQuery } from '@react-hook/media-query';
import simpleFetch from 'very-simple-fetch';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Input from "@mui/material/Input";

import Modals from "../modal";

import './footer.scss';

const Footer = () => {
    const { t: translate } = useTranslation('home');

    const { pathname } = useLocation();
    const isContact = pathname === '/contact';

    const matchedMobile900 = useMediaQuery('only screen and (max-width: 900px)');
    const matchedMobile640 = useMediaQuery('only screen and (max-width: 640px)');
    const matchedMobile320 = useMediaQuery('only screen and (max-width: 320px)');

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [open, setOpen] = React.useState(false);

    const onSubmit_end = async (data) => {
        const respon = simpleFetch({
           url: global.config.webhook_news,
            method: 'POST',
            mode: 'no-cors',
            headers: new Headers({'Content-Type':'application/json'}),
            body: JSON.stringify(data),
        }).then(() => {
            setOpen(true);
            document.getElementById('form__news').reset();
        })
            .catch((error) => {
                console.log('error:');
                console.log(error);
            });
        if(respon){
            console.log(respon)
        }
    };

    return (
        <Box
          className="footer__box"
          sx={
            isContact && {
              padding: !matchedMobile900 && '46px 0 33px',
              backgroundColor: matchedMobile900 ? '#54586926' : '#e9ecef50'
            }
          }
        >
            <Box
              className={'container'}
              sx={
                isContact && {
                  padding: (matchedMobile320 && '27px 26px 19px 23px !important') ||
                  (matchedMobile640 && '8.43vw 8.12vw 5.93vw 7.18vw !important') ||
                  (matchedMobile900 && '54px 52px 38px 46px !important'),
                }
              }
            >
                <Box
                  className={'left'}
                  sx={
                    isContact && {
                      padding: matchedMobile900 && '0 !important',
                      backgroundColor: 'transparent !important',

                      '.copyright': {
                        paddingBottom: matchedMobile900 && '0 !important',
                      }
                    }
                  }
                >
                  <p className="copyright">
                    2022 © Copyright Unicornias Lab
                  </p>
                </Box>

                <Box
                  className={'right'}
                  sx={
                    isContact && {
                      padding: matchedMobile900 && '0 !important',
                      backgroundColor: 'transparent !important'
                    }
                  }
                >
                    <h3
                      sx={
                        isContact && {
                          marginBottom: (matchedMobile320 && '17px !important') ||
                          (matchedMobile640 && '5.31vw !important') ||
                          (matchedMobile900 && '34px !important'),
                        }
                      }
                    >
                        {translate('footerSubscribe')}
                    </h3>

                    <form onSubmit={handleSubmit(onSubmit_end)} id={'form__news'} noValidate style={{justifyContent: 'center'}}>
                        <Box sx={{position: 'relative', width: '100%', justifyContent: 'center'}}>
                            <Box className="inputBox" sx={{position: 'relative', justifyContent: 'center'}}>
                                <Input
                                    className={'input-text'}
                                    placeholder={translate('emailField')}
                                    {...register('email', {
                                        required: true,
                                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    })}
                                />

                                <p className={'error'}>
                                    {errors.email?.type === 'required' && translate('emailIsRequired')}
                                </p>

                                <p className={'error'}>
                                    {errors.email?.type === 'pattern' && translate('emailIsNotValid')}
                                </p>
                            </Box>

                            <Button
                              className="btn"
                              variant="contained"
                              onClick={handleSubmit(onSubmit_end)}
                              sx={
                                isContact && {
                                  marginBottom: (matchedMobile320 && '30px !important') ||
                                  (matchedMobile640 && '9.37vw !important') ||
                                  (matchedMobile900 && '60px !important'),
                                }
                              }
                            >
                                {translate('subscribeBtn')}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>

            <Modals open={open} text={'Thanks for subscribing!'} />
        </Box>
    )
};

export default Footer;
