import React, {
  useState,
} from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Grid,
  IconButton,
  Menu,
} from '@mui/material';

import { ReactComponent as MenuIcon } from 'img/menu.svg';
import logoSrc from 'img/main-logo-mobile.svg';

import './header.scss';

const Header = () => {
  const { t: translate } = useTranslation('home');

  const isMatchedMobile = useMediaQuery('only screen and (max-width: 900px)');

  const [anchorElNav, setAnchorElNav] = useState(null);

  const scrollToDiv = (id) => {
    let path = window.location.pathname;
    let ids = id;
    const divElement = document.getElementById(id);
  
    if (path === '/' && !isMatchedMobile) {
      divElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.location = '/?'+ ids;
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const scrollToDivWithCloseMenu = (id) => {
    setAnchorElNav(null);
    scrollToDiv(id);
  };

  return (
    <Box
      className="header"
      sx={{
        maxWidth: '1170px',
        margin: '0 auto',
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid className="logoGrid" item xs="auto">
          <h1>
            <a href="/">
              <img
                className="logo"
                alt="s4s"
                src={logoSrc}
              />
            </a>
          </h1>
        </Grid>

        <Grid
          className="header__menu"
          item
          xs="auto"
          sx={{display: { xs: 'flex', md: 'none' }}}
        >
          <IconButton
            className="header__menu-icon"
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleOpenNavMenu}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            className="menu__box"
            keepMounted
            id="menu-appbar"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            anchorEl={anchorElNav}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <Link
              className="menu__link"
              onClick={() => scrollToDivWithCloseMenu('about')}
              to="/"
            >
              {translate('headerLinksAbout')}
            </Link>

            <Link
              className="menu__link"
              onClick={() => scrollToDivWithCloseMenu('command')}
              to="/"
            >
              {translate('headerLinksOurTeam')}
            </Link>

            <Link
              className="menu__link"
              onClick={() => scrollToDivWithCloseMenu('faq')}
              to="/"
            >
              {translate('headerLinksFAQ')}
            </Link>

            <Link
              className="menu__link"
              onClick={handleCloseNavMenu}
              to="/contact"
            >
              {translate('headerLinksContact')}
            </Link>
          </Menu>
        </Grid>

        <Grid
          className="header__menu"
          item
          xl={9}
          lg={9}
          sm={9}
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Box className="menu__box">
            <Link
              className="menu__link"
              onClick={() => scrollToDiv('about')}
              to="/"
            >
              {translate('headerLinksAbout')}
            </Link>

            <Link
              className="menu__link"
              onClick={() => scrollToDiv('command')}
              to="/"
            >
              {translate('headerLinksOurTeam')}
            </Link>

            <Link
              className="menu__link"
              onClick={() => scrollToDiv('faq')}
              to="/"
            >
              {translate('headerLinksFAQ')}
            </Link>

            <Link
              className="menu__link"
              to="/contact"
            >
              {translate('headerLinksContact')}
            </Link>

            {/* <SelectLanguage /> TODO: add for multilanguage */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
};

export default Header;
