export const THEME_CONFIG = {
  breakpoints: {
    values: {
        xs: 0,
        sm: 600,
        md: 901,
        lg: 1170,
        xl: 1440,
    },
  },
};

export const APPLY_REDIRECT = 'https://pw82swo8d95.typeform.com/to/m4uSOkYj?typeform-source=app.asana.com';
