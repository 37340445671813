import {
  Routes,
  Route,
  BrowserRouter,
} from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';

import i18n from 'i18n';

import Contact from 'pages/contact';
import Home from 'pages/Home';

import MainLayout from 'components/_layouts/MainLayout/index';

const App = () => {
  const { lang } = useTranslation();

  return (
    <I18nextProvider lang={lang} i18n={i18n}>
      <BrowserRouter>
        <MainLayout>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="contact" element={<Contact />} />
          </Routes>
        </MainLayout>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
