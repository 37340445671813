export const THEME_CONFIG = {
  breakpoints: {
    values: {
        xs: 0,
        sm: 600,
        md: 901,
        lg: 1113,
        xl: 1414,
    },
  },
};
